/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import { Button, Col, Divider, Modal, Row } from "antd";
import { LOGO_LINK, PDF } from "../../Utils/Constant";
import KildeLogo from "../../Assets/Images/kilde-logo-white.svg";
import cookiepolicy from "../../Assets/Pdf/cookie policy.pdf";
import "./style.css";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import ROUTES from "../../Config/Routes";
import { PreferredAppUser } from "../../Apis/UserApi";
import { redirectToVue } from "../../Utils/Helpers";
import arrowLeft from "../../Assets/Images/ArrowLeft.svg";
import JivoChat from "./JivoChat";

const Footer = () => {
  const [pdfModalVisible, setPdfModalVisible] = useState(false);
  const user = useSelector((state) => state?.user);
  const location = useLocation();
  const pathname = location.pathname;
  const firstSlashIndex = pathname.indexOf("/");
  const routeName = pathname.substring(firstSlashIndex + 1);

  const openPdfModal = () => {
    setPdfModalVisible(true);
  };

  const closePdfModal = () => {
    setPdfModalVisible(false);
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//embed.typeform.com/next/embed.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleRedirectToVue = () => {
    PreferredAppUser();
    redirectToVue();
  };

  return (
    <div>
      <div className="kd-footer-div">
        <div>
          <div className="logo-div kd-footer-sub-logo-sb">
            <div>
              <Link to={LOGO_LINK} target="_blank">
                <img src={KildeLogo} alt="kildelogo" />
              </Link>
            </div>
            <div className="footer-media-title-div">
              <p className="footer-media-title mt-4 mb-0">© 2024 Kilde</p>
            </div>
            <div className="media-footer">
              <div className="dashboard-footer-link">
                <div>
                  <Link to={PDF.TERM_OF_USE} target="_blank">
                    Qualification Guidelines for Individuals
                  </Link>
                </div>
                <div>
                  <Link to={PDF.TERM_OF_USE} target="_blank">
                    Terms & Conditions
                  </Link>
                </div>
                <div>
                  <Link
                    to={cookiepolicy}
                    onClick={(e) => {
                      e.preventDefault();
                      openPdfModal();
                    }}
                    target="_blank"
                  >
                    Cookie Policy
                  </Link>
                </div>
                <div>
                  <Link target="_blank" to={PDF.PRIVACY_POLICY}>
                    Privacy Policy
                  </Link>
                </div>
                <div>
                  <Link target="_blank" to={PDF.COMPLAINT_POLICY}>
                    Complaints policy
                  </Link>
                </div>
              </div>
              <div className="dashboard-footer-bottom-media-link">
                <div className="footer-with-border">
                  <p className="footer-title m-0">
                    20 McCallum Street #19-01, Tokio Marine Centre, Singapore,
                    069046
                  </p>
                </div>
                <div className="footer-with-border">
                  <p className="footer-title m-0">
                    <a href="tel:+65 695 077 68"> +65 695 077 68</a>
                  </p>
                </div>
                <div className="footer-with-border">
                  <p className="footer-title m-0">
                    <a href="mailto:sales@kilde.sg">sales@kilde.sg</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Row className="logo-div kd-footer-sub">
            <div>
              <p className="footer-main-title m-0">© 2024 Kilde</p>
            </div>
            <div>
              <Row>
                <div className="dashboard-footer-bottom-link">
                  <div className="footer-with-border">
                    <p className="footer-title m-0">
                      20 McCallum Street #19-01, Tokio Marine Centre, Singapore,
                      069046
                    </p>
                  </div>
                  <div className="footer-with-border">
                    <p className="footer-title m-0">
                      <a href="tel:+65 695 077 68"> +65 695 077 68</a>
                    </p>
                  </div>
                  <div className="footer-with-border">
                    <p className="footer-title m-0">
                      <a href="mailto:sales@kilde.sg">sales@kilde.sg</a>
                    </p>
                  </div>
                </div>
              </Row>
            </div>
          </Row>
          <div className="footer-divider-div">
            <Divider plain />
          </div>
          <Row className="kd-footer-sub-bottom">
            <Col xs={24} sm={24} md={20} lg={15}>
              <p className="footer-botton-title m-0">
                Disclaimer: KILDE PTE LTD is incorporated in Singapore under the
                registration no. 201929587K and holds a Capital Markets Services
                licence (CMS101016) issued by the Monetary Authority of
                Singapore to deal in capital markets products under the
                Securities and Futures Act (Cap. 289) and an Exempted Financial
                Advisor License under the Financial Adviser Act. The information
                on this website is intended for “institutional investors” and
                “accredited investors”, in accordance with the Securities and
                Futures Act (Cap. 289) of Singapore. Information provided on
                this website does not constitute an offer, invitation to invest,
                solicitation or advice on buying or selling financial
                instruments and other capital market products.
              </p>
            </Col>
          </Row>
        </div>
      </div>
      <Modal
        className="sb-pdf-modal"
        centered
        open={pdfModalVisible}
        onCancel={closePdfModal}
        width={1000}
        footer={null}
      >
        <iframe
          className="mt-20"
          src={cookiepolicy}
          width="100%"
          height="500px"
          title="PDF Viewer"
        />
      </Modal>
      {user?.vwoFeatures?.goBackToVue?.goBackToVueButtonHide === false &&
      "/" + routeName !== ROUTES.INDIVIDUAL_VERIFICATION &&
      "/" + routeName !== ROUTES.VERIFICATION &&
      "/" + routeName !== ROUTES.ORGANIZATION_VERIFICATION ? (
        <Button
          className="dashboard-go-back-btn"
          onClick={handleRedirectToVue}
          id="go-back-to-vue"
        >
          <img src={arrowLeft} alt="arrowleft" />
          <span id="go-back-to-vue">Back To The Old Look</span>
        </Button>
      ) : null}
      {/* <SupportChatButton /> */}
      <JivoChat user={user} />
    </div>
  );
};

export default Footer;
