import React, { useState } from "react";
import ButtonDefault from "../../../Components/ButtonDefault/ButtonDefault";
import { Button, Col, Modal, Row, message } from "antd";
import { CancelInvest, InvestTranche } from "../../../Apis/DashboardApi";
import { setTrancheResponse } from "../../../Redux/Action/Investor";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ErrorResponse } from "../../../Utils/ErrorResponse";
import {
  formatCurrency,
  showMessageWithCloseIcon,
} from "../../../Utils/Reusables";

const InvestDetails = ({ TrancheRes, setLoader }) => {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const [cancelInvestLoading, setCancelInvestLoading] = useState(false);
  const [cancelInvestModal, setCancelInvestModal] = useState(false);

  const handleCancelInvestment = () => {
    setCancelInvestLoading(true);
    const requestBody = {
      trancheUuid: TrancheRes?.tranche?.uuid,
    };
    CancelInvest(requestBody)
      .then(async (cancelInvestmentData) => {
        if (Object.keys(cancelInvestmentData)?.length > 0) {
          showMessageWithCloseIcon(
            "Your committed investment is successfully canceled.."
          );
          setCancelInvestLoading(false);
          setCancelInvestModal(false);
          handleGetTranche();
        } else {
          setCancelInvestLoading(false);
        }
      })
      .catch((error) => {
        ErrorResponse(error?.code);
        setCancelInvestLoading(false);
      });
  };

  const handleGetTranche = () => {
    setLoader(true);
    const requestBody = {
      trancheUuid: slug,
    };
    InvestTranche(requestBody).then(async (tracheRes) => {
      await setTrancheResponse(tracheRes, dispatch);
      setLoader(false);
    });
  };

  return (
    <Col
      xs={24}
      sm={24}
      md={24}
      lg={24}
      className="mt-10 infomation-div medium-tranch-col"
    >
      <div className="sb-flex-justify-between invest-detail-media-div">
        <div>
          <p className="m-0 tranch-head">Your investments details</p>
        </div>
        {TrancheRes?.investment?.principalInvested !== 0 && (
          <div>
            <p className="m-0 tranch-head inv-detail-val-media">
              {formatCurrency(
                TrancheRes?.tranche?.currencyCode === "USD"
                  ? "$"
                  : TrancheRes?.tranche?.currencyCode === "SGD"
                  ? "S$"
                  : "€",
                TrancheRes?.investment?.principalInvested
              )}
            </p>
          </div>
        )}
      </div>
      {TrancheRes?.investment?.principalInvested === 0 && (
        <p className="mt-16 no-data-text">
          You have no investments in the tranche
        </p>
      )}
      {TrancheRes?.investment?.principalInvested > 0 && (
        <>
          <div className="sb-flex-justify-between">
            <div>
              <p className="trache-details mb-0">Funded investment</p>
            </div>
            <div className="trache-details mb-0">
              <p>
                {formatCurrency(
                  TrancheRes?.tranche?.currencyCode === "USD"
                    ? "$"
                    : TrancheRes?.tranche?.currencyCode === "SGD"
                    ? "S$"
                    : "€",
                  TrancheRes?.investment?.principalSettled
                )}
              </p>
            </div>
          </div>
          <div className="sb-flex-justify-between">
            <div>
              <p className="trache-details mt-0">Committed investments</p>
            </div>
            <div>
              <p className="trache-details mt-0">
                {formatCurrency(
                  TrancheRes?.tranche?.currencyCode === "USD"
                    ? "$"
                    : TrancheRes?.tranche?.currencyCode === "SGD"
                    ? "S$"
                    : "€",
                  TrancheRes?.investment?.principalSubscribed
                )}
              </p>
            </div>
          </div>
          {TrancheRes?.investment?.principalSubscribed > 0 && (
            <Row className="invest-detail-div">
              <Col sx={24} sm={24} md={18}>
                <p>
                  Thank you for investing with us! We'll handle your investment
                  and complete everything within the next 24 hours. If you
                  decide to change your mind, you can simply click the "cancel"
                  button before we finish processing.
                </p>
              </Col>

              <Col sx={24} sm={24} md={6} className="sb-text-align-end w-100">
                <ButtonDefault
                  title="Cancel"
                  loading={cancelInvestLoading}
                  onClick={() => {
                    setCancelInvestModal(true);
                  }}
                />
              </Col>
            </Row>
          )}
        </>
      )}

      <Modal
        centered
        open={cancelInvestModal}
        onCancel={() => {
          setCancelInvestModal(false);
        }}
        width={464}
        footer={null}
        maskClosable={false}
        className="withdraw-modal"
        closable={false}
      >
        <p className="mt-0 wallet-sub-head mb-24 sb-text-align">
          Are you sure you want to Cancel committed investment?
        </p>

        <div className="sb-text-align d-flex">
          <Button
            className="remove-modal-back-btn mr-8 w-100"
            onClick={() => {
              setCancelInvestModal(false);
            }}
          >
            No
          </Button>
          <ButtonDefault
            loading={cancelInvestLoading}
            style={{ width: "100%" }}
            title="Yes"
            onClick={handleCancelInvestment}
          />
        </div>
      </Modal>
    </Col>
  );
};

export default InvestDetails;
