import React, { useState } from "react";
import DashboardLayout from "../../../Layouts/DashboardLayout/DashboardLayout";
import { Button, Col, Row } from "antd";
import AccountStatementFilterComponent from "./AccountStatementFilterComponent";
import SlidersHorizontal from "../../../Assets/Images/SlidersHorizontal.svg";
import Download_icon from "../../../Assets/Images/download_icon.svg";
import Download_icon_disable from "../../../Assets/Images/dowloadicondisable.svg";
import ShowAccountStatementFilterModal from "./ShowAccountStatementFilterModal";
import AccountSummaryTable from "./AccountSummaryTable";
import TransactionTable from "./TransactionTable";
import { useSelector } from "react-redux";
import { AccountStatementSummaryDownload } from "../../../Apis/DashboardApi";
import FinishOnboarding from "../Investment/FinishOnboarding";

const AccountStatement = () => {
  const [filterModal, setFilterModal] = useState(false);
  const [loadExcel, setLoadExcel] = useState(false);
  const filterData = useSelector(
    (state) => state?.dashboards?.DashboardData?.accStatementFilterData
  );
  const postingDateFrom = filterData?.postingDateFrom;
  const postingDateTo = filterData?.postingDateTo;
  const currencyCode = filterData?.currencyCode;
  const txType = filterData?.txType;
  const user = useSelector((state) => state?.user);
  const accountNo = useSelector(
    (state) => state?.wallet?.bankAccount?.bankAccounts
  );

  const accountSummary = useSelector(
    (state) => state?.dashboards?.DashboardData?.transactionData
  );

  const handleExportXLS = async () => {
    try {
      setLoadExcel(true);
      const payload = {
        page: 1,
        pageSize: 100,
        postingDateFrom: postingDateFrom || null,
        postingDateTo: postingDateTo || null,
        currencyCode: currencyCode || "USD",
        txType:
          txType !== ""
            ? txType === "withdrawal_request"
              ? "WITHDRAWAL_REQUEST"
              : txType
            : null,
      };
      AccountStatementSummaryDownload(payload)
        .then(async (response) => {
          if (!response.data || response.data.size === 0) {
            throw new Error("Empty file received from server");
          }

          const contentType = response.headers["content-type"];
          const blob = new Blob([response.data], { type: contentType });

          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = response.headers["k-filename"] || "download.xlsx";
          link.click();
          setLoadExcel(false);
        })
        .catch((error) => {
          console.error("Error fetching account summary:", error);
          setLoadExcel(false);
        });
    } catch (e) {
      console.error("Error fetching account summary:", e);
      setLoadExcel(false);
    }
  };

  return (
    <div>
      <DashboardLayout>
        <div className="trance-listing-main-div">
          {user?.investorStatus !== "ACTIVE" ||
          (user?.investorStatus === "ACTIVE" && accountNo?.length <= 0) ||
          (user?.secondFactorAuth === null &&
            user?.twoFaCheckEnabled === true) ? (
            <div className="mb-24">
              <FinishOnboarding />
            </div>
          ) : null}
          <div>
            <Row gutter={window.innerWidth >= 768 ? 20 : 0}>
              <Col xs={5} className="invest-filter">
                <AccountStatementFilterComponent />
                <ShowAccountStatementFilterModal
                  filterModal={filterModal}
                  setFilterModal={setFilterModal}
                />
              </Col>

              <Col xs={24} md={19} lg={19} className="gutter-row">
                <div className="account-summary-main-div">
                  <p className="tranche-header m-0">Account Statement</p>
                  <div>
                    {user?.investorStatus === "ACTIVE" &&
                    (accountSummary?.turnovers?.length > 0 ||
                      accountSummary?.txs?.length > 0) ? (
                      <Button
                        loading={loadExcel}
                        onClick={handleExportXLS}
                        style={{ backgroundColor: "transparent" }}
                        className="cursor-pointer"
                      >
                        <p>Export to Excel</p>
                        <img src={Download_icon} alt="Download_icon" />
                      </Button>
                    ) : (
                      <Button
                        loading={loadExcel}
                        onClick={handleExportXLS}
                        disabled={
                          user?.investorStatus !== "ACTIVE" ||
                          (accountSummary?.turnovers?.length < 0 &&
                            accountSummary?.txs?.length < 0)
                        }
                        style={{
                          backgroundColor: "transparent",
                        }}
                        className="cursor-pointer"
                      >
                        <p
                          style={{
                            color: "var(--black-40, rgba(26, 32, 44, 0.40))",
                          }}
                        >
                          Export to Excel
                        </p>
                        <img src={Download_icon_disable} alt="Download_icon" />
                      </Button>
                    )}

                    {/* <Dropdown
                      className="cursor-pointer"
                      overlay={
                        <Menu className="account-statement-export-dropdown">
                          <Menu.Item key="0">
                            <a
                              href="https://www.kilde.sg/about"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <div className="export-excel">
                                <img src={File_xls} alt="File_xls" />
                                Export as Excel
                              </div>
                            </a>
                          </Menu.Item>
                          <Menu.Item key="1">
                            <a
                              href="https://www.kilde.sg/team"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <div className="export-pdf">
                                <img src={File_pdf} alt="File_pdf" />
                                Export as PDF
                              </div>
                            </a>
                          </Menu.Item>
                        </Menu>
                      }
                      trigger={["click"]}
                    >
                      <Space>
                        <p>Download Report</p>
                        <img src={Download_icon} alt="Download_icon" />
                      </Space>
                    </Dropdown> */}
                  </div>
                </div>
                <Col xs={24} className="invest-col mt-24">
                  <Button
                    className="invest-showfilterbutton"
                    onClick={() => setFilterModal(true)}
                    style={{ height: 36 }}
                  >
                    Show Filtres
                    <img src={SlidersHorizontal} alt="img" />
                  </Button>
                </Col>
                <Col xs={24} className="mt-24">
                  <AccountSummaryTable />
                  <div className="mt-24">
                    <TransactionTable />
                  </div>
                </Col>
              </Col>
            </Row>
          </div>
        </div>
      </DashboardLayout>
    </div>
  );
};

export default AccountStatement;
